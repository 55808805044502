<script>
import TableView from '@/components/TableVIew'
import { getAction } from '@/command/netTool'
import moment from 'moment'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'

export default {
  name: 'userManagement',
  components: { TableView },
  props: {
    acData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      currentPage: 1,
      dataSource: [],
      dataSource1: [],
      objInfo: {},
      searchP: {
        telephone: '',
        endTime: '',
        startTime: ''
      }
    }
  },
  methods: {
    getColumns() {
      return [
        {
          title: '用户',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: '联系方式',
          dataIndex: 'telephone',
          align: 'center'
        },
        {
          title: '累积签到',
          dataIndex: 'cumulativeSignIn',
          align: 'center'
        },
        {
          title: '最长连续签到',
          dataIndex: 'longestSignIn',
          align: 'center'
        },
        {
          title: '最近签到时间',
          dataIndex: 'lastSignInTime',
          align: 'center'
        }
      ]
    },
    changePage({ current }) {
      this.currentPage = current
    },
    getInit() {
      const { id } = this.acData
      const { startTime, endTime, telephone } = this.searchP
      getAction(
        `/sign_in/statistics?signId=${id}&startTime=${startTime}&endTime=${endTime}&telephone=${telephone}`
      ).then(res => {
        const { data } = res
        if (data) {
          const { users, awards } = data
          //   this.objInfo = {}
          this.dataSource = users
          this.dataSource1 = awards
        }
      })
    },
    renderData() {
      return this.dataSource1.map(e => {
        return {
          value: e.number,
          name: `${e.goodsName}${e.goodsUnit ? '(' + e.goodsUnit + ')' : ''}`
        }
      })
    },
    onChangeRange(date, dateString) {
      if (dateString.length) {
        this.searchP.startTime = dateString[0]
        this.searchP.endTime = dateString[1]
      } else {
        this.searchP.startTime = ''
        this.searchP.endTime = ''
      }
    }
  },
  mounted() {
    const { id } = this.acData
    if (id) {
      this.getInit()
    }
  },
  render() {
    return (
      <div class="user-box">
        <div class="middle-box">
          {this.renderData().map(e => {
            return (
              <div class="middle-box-div">
                <span>{e.value ?? 0}</span>
                <span>{e.name}</span>
              </div>
            )
          })}
        </div>
        <div class="top-box">
          <div class="top-box-div">
            <span>签到时间：</span>
            <a-range-picker
              onChange={this.onChangeRange}
              placeholder={['开始日期', '结束日期']}
              format="YYYY-MM-DD HH:mm:ss"
              show-time
              locale={locale}
            ></a-range-picker>
            <a-button style={{ marginLeft: '15px' }} onClick={this.getInit}>
              查询
            </a-button>
          </div>
          <div class="top-box-div">
            <span>联系方式：</span>
            <a-input
              v-model={this.searchP.telephone}
              placeholder="联系方式"
              style={{ width: '48%' }}
              allowClear
            ></a-input>
          </div>
        </div>
        <div class="bottom-box">
          <TableView
            bordered
            columns={this.getColumns()}
            dataSource={this.dataSource}
            showRowSelect={false}
            pagination={{
              showSizeChanger: false,
              defaultPageSize: 7,
              hideOnSinglePage: true,
              showQuickJumper: false,
              hideOnSinglePage: true,
              current: this.currentPage
            }}
            onChange={this.changePage}
          />
        </div>
      </div>
    )
  }
}
</script>

<style lang="less" scoped>
.user-box {
  width: 100%;
  height: 100%;

  .top-box {
    .top-box-div {
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }

  .middle-box {
    width: 100%;
    min-height: 100px;
    border: 1px solid rgba(232, 234, 236, 1);
    border-radius: 4px;
    margin: 0 auto 15px;
    display: flex;
    flex-wrap: wrap;

    &-div {
      width: 25%;
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 0 10px;
      box-sizing: border-box;

      span {
        text-align: center;
        width: 100%;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
      }

      &:nth-last-of-type(1),
      &:nth-child(4n) {
        &::after {
          display: none;
        }
      }

      &::after {
        content: '';
        width: 1px;
        height: 52px;
        background-color: rgba(232, 234, 236, 1);
        position: absolute;
        right: 0;
      }

      span {
        font-family: 'PingFang SC ', 'PingFang SC', sans-serif;
        font-weight: 500;
        font-style: normal;
        color: #515a6e;
        &:nth-of-type(1) {
          font-size: 20px;
        }
        &:nth-of-type(2) {
          font-size: 14px;
        }
      }
    }
  }

  .bottom-box {
    /deep/ .ant-table-pagination {
      width: 100%;
      text-align: center;
    }
  }
}
</style>
