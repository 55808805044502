import apiTool from '@/command/apiTool'
import { getAction, postAction, delAction } from '@/command/netTool'
import DrawerForm from '@/components/DrawerForm'
import { Message } from 'ant-design-vue'
import RewardSetting from '../com/sign_reward/RewardSetting.vue'
import UserManagement from '../com/sign_reward/UserManagement.vue'

let formData = {}
let pageTotal = 10,
  pageCurrent = 1

async function onFetchInit(data) {
  const { total } = data
  pageTotal = total

  return {
    form: data
  }
}

//活动添加编辑
function getForm(form, { update }) {
  return [
    {
      form: [
        {
          name: '签到名称',
          type: 'input',
          key: 'name',
          disabled: formData.status == '1',
          rules: [
            {
              required: true,
              message: '请输签到名称'
            }
          ]
        },
        {
          name: '签到类型',
          type: 'radioButton',
          key: 'type',
          disabled: formData.status == '1',
          typeData: [
            {
              name: '累积签到',
              value: '1'
            },
            {
              name: '连续签到',
              value: '2'
            }
          ],
          rules: [
            {
              required: true,
              message: '请选择签到类型'
            }
          ]
        },
        {
          name: '签到周期',
          type: 'radioButton',
          key: 'periodStatus',
          disabled: formData.status == '1',
          typeData: [
            {
              name: '7天',
              value: '1'
            },
            {
              name: '30天',
              value: '2'
            },
            {
              name: '自定义',
              value: '3'
            }
          ],
          rules: [
            {
              required: true,
              message: '请选择签到周期'
            }
          ]
        },
        ...[
          {
            type: 'inputNumber',
            key: 'period',
            label: '天',
            display: form.periodStatus == '3',
            disabled: formData.status == '1',
            desc: ' 天',
            props: {
              min: 0
            },
            styleBox: {
              width: '270px',
              display: 'flex',
              marginLeft: '14.5%',
              alignItems: 'center',
              justifyContent: 'space-between'
            },
            styles: {
              width: '130px'
            }
          }
        ].filter(e => e.display),
        {
          name: '积分',
          type: 'inputNumber',
          key: 'point',
          disabled: formData.status == '1',
          props: {
            min: 0
          }
        },
        {
          name: '成长值',
          type: 'inputNumber',
          key: 'growth',
          disabled: formData.status == '1',
          props: {
            min: 0
          }
        },
        {
          name: '用户人群',
          type: 'radioButton',
          key: 'crowd',
          disabled: formData.status == '1',
          typeData: [
            {
              name: '老用户',
              value: '2'
            },
            {
              name: '新注册用户',
              value: '1'
            }
          ],
          rules: [
            {
              required: true,
              message: '请选择用户人群'
            }
          ]
        },
        {
          name: '循环周期',
          type: 'switch',
          key: 'recylePeriod',
          disabled: formData.status == '1',
          rules: [
            {
              required: true,
              message: '请选择循环周期'
            }
          ]
        },

        {
          name: '活动规则',
          type: 'textArea',
          key: 'rules',
          rules: [
            {
              required: true,
              message: '请输入活动规则'
            }
          ]
        }
      ]
    }
  ]
}

//活动添加/编辑
function onOpen({ getDetail }, title) {
  apiTool.showDrawer({
    title: title,
    view: DrawerForm,
    width: '700px',
    viewProps: {
      formProps: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 }
      },
      form: formData,
      data: getForm
    },
    success({ data, setHidden }) {
      if (JSON.stringify(data) === '{}') {
        Message.warning('请完成表单填写')
        return
      }

      let params = {
        ...data,
        recylePeriod: data.recylePeriod == 1 ? true : false,
        period: { '1': 7, '2': 30, '3': data.period }[data.periodStatus]
      }
      postAction('/sign_in/add_or_modify', params).then(res => {
        Message.success('保存成功')
        getDetail()
        setHidden()
      })
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '取消',
          onClick: close,
          display: true
        },
        {
          name: '保存',
          type: 'primary',
          onClick: submit,
          display: title != '商品详情'
        }
      ].filter(e => e.display)
    }
  })
}

function rewardSetting({ getDetail }, title, { id, status }) {
  apiTool.showDrawer({
    title: title,
    view: RewardSetting,
    width: '780px',
    viewProps: {
      acData: { activityId: id, activityStatus: status }
    },
    success({ data, setHidden }) {
      console.log(data)
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '取消',
          onClick: close
        }
        // {
        //   name: '保存',
        //   type: 'primary',
        //   onClick: submit
        // }
      ]
    }
  })
}

function userManagement({ getDetail }, title, params) {
  apiTool.showDrawer({
    title: title,
    view: UserManagement,
    width: '780px',
    viewProps: {
      acData: params
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '取消',
          onClick: close
        }
      ]
    }
  })
}

export default function({ getDetail }) {
  return {
    type: 'cardFormTable',
    url: '/sign_in/page',
    params: {
      size: 10,
      current: pageCurrent
    },
    onFetchInit,
    data: bgData => {
      {
        return [
          {
            form: [
              {
                label: '新建活动',
                type: 'button',
                cols: 3,
                props: {
                  type: 'primary',
                  ghost: true,
                  style: {
                    width: '100px'
                  }
                },
                onClick: () => {
                  formData = { period: '', point: '', growth: '' }
                  onOpen({ getDetail }, '新建活动')
                }
              },
              {
                type: 'table',
                labelCol: { span: 0 },
                wrapperCol: { span: 24 },
                dataSource: bgData['records'],
                showFootButton: false,
                showRowSelect: false,
                showPagination: true,
                pagination: {
                  total: pageTotal,
                  hideOnSinglePage: true,
                  showSizeChanger: false,
                  onChange: (page, pageSize) => {
                    pageCurrent = page
                    getDetail()
                  }
                },
                columns: [
                  {
                    dataIndex: 'name',
                    title: '签到名称',
                    align: 'left',
                    customRender: function(text, records, index, h) {
                      return (
                        <div class="product-order-item-copy">
                          <div>{text}</div>
                        </div>
                      )
                    }
                  },
                  {
                    dataIndex: 'takeUpTime',
                    title: '上架时间',
                    align: 'left'
                  },
                  {
                    dataIndex: 'takeDownTime',
                    title: '下架时间',
                    align: 'left'
                  },
                  {
                    dataIndex: 'status',
                    title: '状态',
                    type: 'badge',
                    onExport: records => {
                      return ['未开始', '活动中', '已结束'][records]
                    },
                    filterMultiple: false,
                    render(data) {
                      return {
                        showDot: true,
                        name: ['未开始', '活动中', '已结束'][data],
                        color: ['#f59a23', '#18be6b', '#ed4014'][data]
                      }
                    }
                  },
                  {
                    title: '操作',
                    customRender: function customRender(text, records, index, h) {
                      return (
                        <div>
                          <a
                            v-show={records.status != 2}
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                              if (records.publishStatus == 1) {
                                getAction(`/sign_in/takeDown/${records.id}`).then(res => {
                                  Message.error('下架成功!')
                                  getDetail()
                                })
                              } else {
                                getAction(`/sign_in/takeUp/${records.id}`).then(res => {
                                  Message.success('上架成功!')
                                  getDetail()
                                })
                              }
                            }}
                          >
                            {records.publishStatus == 1 ? '下架' : '上架'}
                          </a>
                          <a
                            v-show={records.status != 2}
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                              getAction(`/sign_in/detail/${records.id}`).then(res => {
                                const { data } = res
                                formData = {
                                  ...data,
                                  recylePeriod: data.recylePeriod ? '1' : '0'
                                }
                                onOpen({ getDetail }, '编辑商品')
                              })
                            }}
                          >
                            编辑
                          </a>
                          <a
                            v-show={records.status == 2}
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                              getAction(`/sign_in/detail/${records.id}`).then(res => {
                                formData = res.data
                                onOpen({ getDetail }, '商品详情')
                              })
                            }}
                          >
                            详情
                          </a>
                          <a
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                              userManagement({ getDetail }, '签到记录', records)
                            }}
                          >
                            签到记录
                          </a>
                          <a
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                              rewardSetting({ getDetail }, '奖励设置', records)
                            }}
                          >
                            奖励设置
                          </a>
                          <a
                            v-show={records.status == 2}
                            onClick={() => {
                              delAction(`/sign_in/delete?ids=${records.id}`).then(res => {
                                Message.success('删除成功!')
                                getDetail()
                              })
                            }}
                          >
                            删除
                          </a>
                        </div>
                      )
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  }
}
